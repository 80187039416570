@import "Colors.scss";

.Popup-wrap-container{
    width: max-content;
    max-width: 310px;
    border-radius: 5px;
    background-color: $white;
    color: $darkColor;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    padding: 15px 25px;
    z-index: 1500;
}

.Popup-wrap{
    position: relative;
}

.Popup-wrap-container.error .Popup-wrap-message{
    border: 1px solid $red;
}

.Popup-wrap-message{
    background-color: $grey;    
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
}

:focus{
    outline:  0 !important;
}
.Popup-wrap .close{
    position: absolute;
    top: -16px;
    right: -24px;
    margin: auto;
    border: 0em;
}

.Popup-wrap button span:active,
.Popup-wrap button span{
    height: 40px;
    width: 30px;
    display: block;
    float: none;
    border: 0;
    font-size: 30px;
}

.Popup-wrap-container.success{
    border: 1px solid $lightGreenColor;
    height: fit-content;
}

.Popup-wrap-container.error{
    height: 270px;
    border: 1px solid $red;
    background: url("../../../public/mysak.png");
    background-size: cover;
    background-position-y: 30px;
    background-repeat: no-repeat;
    background-color: $white;
}

.Popup-wrap-container.correct{
    height: fit-content;
    border: 1px solid $lightGreenColor;
    background: initial;
    background-size: cover;
    background-position-y: 30px;
    background-repeat: no-repeat;
    background-color: $white;
}